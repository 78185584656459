import _ from 'lodash'
import firebase from 'firebase/app'
import React from 'react'
import ReactDOM from 'react-dom'

import 'firebase/auth'
import 'firebase/firestore'

var app = window.app = window.app || {}

class AdminApp extends React.Component {
  render () {
    const { docs } = this.props
    return (
      <div className='AdminApp'>
        <table className='Requests'>
          <thead>
            <tr>
              <th className='RequestCell RequestCell--left'>Email</th>
              <th className='RequestCell'>Time</th>
              <th className='RequestCell'>Time on site</th>
              <th className='RequestCell RequestCell--left'>URL</th>
              <th className='RequestCell'>Screen</th>
            </tr>
          </thead>
          <tbody>
            {docs.map(row => (
              <tr key={row.id}>
                <td className='RequestCell RequestCell--left'>{_.get(row, 'email')}</td>
                <td className='RequestCell'>{_.get(row, 'ts') && _.get(row, 'ts').toDate ? _.get(row, 'ts').toDate().toISOString() : '-'}</td>
                <td className='RequestCell'>{_.get(row, 'meta.delay')}</td>
                <td className='RequestCell RequestCell--left'>{_.get(row, 'meta.url')}</td>
                <td className='RequestCell'>{_.get(row, 'meta.screen.width') + 'x' + _.get(row, 'meta.screen.height')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

console.log(firebase)
firebase.initializeApp({
  apiKey: 'AIzaSyD5NaeeeGcV8QBvc5GBMlVA3OApchsWE9E',
  authDomain: 'testem-landing.firebaseapp.com',
  databaseURL: 'https://testem-landing.firebaseio.com',
  projectId: 'testem-landing'
})

window.app.login = function () {
  var provider = new firebase.auth.GoogleAuthProvider()
  firebase.auth().signInWithRedirect(provider)
  firebase.auth().getRedirectResult().catch(function (err) {
    console.log(err)
  })
}

window.app.listRequests = function listRequests () {
  ReactDOM.render(<div>Loading...</div>, document.getElementById('root'))

  var db = firebase.firestore()
  db.settings({ timestampsInSnapshots: true })
  db.collection('access-requests').orderBy('ts', 'desc').get().then((list) => {
    const docs = []
    list.forEach((doc) => {
      docs.push(Object.assign({ id: doc.id }, doc.data()))
      ReactDOM.render(<AdminApp docs={docs} />, document.getElementById('root'))
    })
  }).catch(err => {
    console.log(err)
    ReactDOM.render(<div className='Error'>{err.message}</div>, document.getElementById('root'))
  })

  db.collection('access-requests').orderBy('ts', 'desc').onSnapshot(function (querySnapshot) {
    const docs = []
    querySnapshot.forEach(function (doc) {
      docs.push(Object.assign({ id: doc.id }, doc.data()))
      ReactDOM.render(<AdminApp docs={docs} />, document.getElementById('root'))
    })
  })
}

firebase.auth().onAuthStateChanged(function (user) {
  app.user = user

  if (!user) {
    app.login()
  } else {
    app.listRequests()
  }
})

app.logout = function () {
  firebase.auth().signOut()
}
